<template>
  <el-upload
      class="avatar-uploader"
      action="/api/v1/avatar/upload"
      accept="image/*"
      name="avatar"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :file-list="fileList"
      :auto-upload="true"
  >
    <el-image v-if="imageUrl" :src="imageUrl" class="avatar" fit="contain" />
    <el-icon v-else class="avatar-uploader-icon"><Avatar></Avatar></el-icon>
  </el-upload>
  <el-card  v-if="cartoonUrl" class="avatar-card">
    <template #header>
      <span>卡通化肖像</span>
    </template>
    <el-image :src="cartoonUrl" class="avatar" fit="contain" />
  </el-card>
</template>
<script setup>
import { ref } from 'vue'
import { Avatar } from '@element-plus/icons-vue'
import {ElMessage} from "element-plus";

const fileList = ref([])
const imageUrl = ref()
const cartoonUrl = ref()

const handleAvatarSuccess = (
    response,
    uploadFile
) => {
  if(response.status === 'success') {
    imageUrl.value = response.raw
    if(response.cartoon){
      cartoonUrl.value = response.cartoon
    }else {
      cartoonUrl.value = null
    }
  }else {
    imageUrl.value = URL.createObjectURL(uploadFile.raw)
  }
}


const beforeAvatarUpload = (rawFile) => {
  if (!rawFile.type.startsWith('image/')) {
    ElMessage.error('Avatar picture must be JPG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 10) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
</script>
<style>
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
}
</style>
<style scoped>
.avatar-card{
  width: max-content;
  margin: auto;
}
.avatar{
  width: 320px;
  height: 320px;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
}
</style>
